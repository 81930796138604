export const checkIfHasVariants = (item) =>
  item.caseModels && item.caseModels.length>0

export const checkIfVariantsMissing = (item, selectedVariants) => {
  if (checkIfHasVariants(item)) {
    return (
      (item.caseModels &&
        item.caseModels.length > 0 &&
        selectedVariants &&
        selectedVariants.caseModelSelected.value === "") ||
        (item.caseModels &&
          item.caseModels.length > 0 &&
          selectedVariants &&
          selectedVariants.caseModelSelected.value !== "" &&selectedVariants.caseModelSelected.colors &&
          selectedVariants.caseModelSelected.colors.length > 0 && selectedVariants.colorSelected.value==="")
    );
  }
  return checkIfHasVariants(item);
};
